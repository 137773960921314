import { GridLayoutId, HexId, RichTextDocument } from "@hex/common";

import { CellFilterShowType } from "../components/sidebar/constants.js";
import {
  COLLAPSED_SIDEBAR_WIDTH,
  SidebarTab,
} from "../components/sidebar/SidebarOptions.js";
import { DEFAULT_GRAPH_WIDTH } from "../views/constants.js";

import {
  BrowserStorageKey,
  useBrowserStorageGetter,
  useBrowserStorageSetter,
  useBrowserStorageValue,
  useDynamicBrowserStorageGetter,
  useDynamicBrowserStorageSetter,
} from "./useBrowserStorage.js";

export type SessionStorageKey<V> = {
  keyName: string;
  default: V;
};

export interface StoredScrollState {
  /** The hexId for which the scroll state was saved */
  hexId: HexId | null;
  /** The last vertical scroll position */
  scrollPosition: number | null;
  /** For layout view, the last active tab */
  layoutActiveTabId?: GridLayoutId | null;
}

/**
 * sessionStorage is similar to localStorage;
 * the difference is that while data in localStorage doesn't expire,
 * data in sessionStorage is cleared when the page session ends
 */
export const SessionStorageKeys = {
  SCROLL_STATE: (
    view: "logic" | "layout",
  ): SessionStorageKey<StoredScrollState> => ({
    keyName: `${view}_scroll_state`,
    default: {
      hexId: null,
      scrollPosition: null,
    },
  }),
  REVIEW_REQUEST_EDITOR: (
    reviewRequestLinkId: string,
  ): SessionStorageKey<null | RichTextDocument> => ({
    keyName: `review_request_editor_${reviewRequestLinkId}`,
    default: null,
  }),
  APP_SIDEBAR_VIEW: (
    hexId: HexId,
  ): BrowserStorageKey<"comments" | "schedules" | null> => ({
    keyName: `app_sidebar_${hexId}`,
    default: null,
  }),
  SCHEDULES_SIDEBAR_TAB: (
    hexId: HexId,
  ): BrowserStorageKey<"notifications" | "runs"> => ({
    keyName: `schedules_sidebar_${hexId}`,
    default: "notifications",
  }),
  CELL_FILTER_SHOW_TYPE: (
    hexId: HexId,
  ): SessionStorageKey<
    (typeof CellFilterShowType)[keyof typeof CellFilterShowType]
  > => ({
    keyName: `cell_filter_show_type_${hexId}`,
    default: CellFilterShowType.All,
  }),
  GRAPH_OPEN: (hexId: HexId) => ({
    keyName: `hex_${hexId}_graph_open`,
    default: false,
  }),
  GRAPH_WIDTH: (hexId: HexId) => ({
    keyName: `hex_${hexId}_graph_width`,
    default: DEFAULT_GRAPH_WIDTH,
  }),
  DIALOG_GRAPH_OPEN: (hexId: HexId) => ({
    keyName: `hex_${hexId}_dialog_graph_open`,
    default: false,
  }),
  DIALOG_GRAPH_WIDTH: (hexId: HexId) => ({
    keyName: `hex_${hexId}_dialog_graph_width`,
    default: DEFAULT_GRAPH_WIDTH,
  }),
  OUTLINE_COLLAPSED: (hexId: HexId) => ({
    keyName: `hex_${hexId}_outline_collapsed`,
    default: true,
  }),
  SIDEBAR_VIEW: (hexId: HexId) => ({
    keyName: `hex_${hexId}_sidebar_view`,
    default: SidebarTab.OUTLINE,
  }),
  OUTLINE_V2_VIEW: (hexId: HexId) => ({
    keyName: `hex_${hexId}_sidebar_outline_v2_view`,
    default: SidebarTab.OUTLINE,
  }),
  SIDEBAR_WIDTH: (hexId: HexId) => ({
    keyName: `hex_${hexId}_sidebar_width`,
    default: COLLAPSED_SIDEBAR_WIDTH,
  }),
  HIGHLIGHT_VARIABLE_SECTION: (
    hexId: HexId,
    section: "hex_user_attributes",
  ) => ({
    keyName: `hex_${hexId}_highlight_variable_${section}`,
    default: false,
  }),
  OUTLINE_DOCKED: (hexId: HexId) => ({
    keyName: `hex_${hexId}_outline_docked`,
    default: false,
  }),
};

export function useSessionStorageGetter<V>(key: BrowserStorageKey<V>): () => V {
  return useBrowserStorageGetter("Session", key);
}
export function useDynamicSessionStorageGetter(): <V>(
  key: BrowserStorageKey<V>,
) => V {
  return useDynamicBrowserStorageGetter("Session");
}
export function useSessionStorageSetter<V>(
  key: BrowserStorageKey<V>,
): (value: ((oldValue: V) => V) | V) => void {
  return useBrowserStorageSetter("Session", key);
}
export function useDynamicSessionStorageSetter(): <V>(
  key: BrowserStorageKey<V>,
  value: ((oldValue: V) => V) | V,
) => void {
  return useDynamicBrowserStorageSetter("Session");
}
export function useSessionStorageValue<V>(key: BrowserStorageKey<V>): V {
  return useBrowserStorageValue("Session", key);
}

export function useSessionStorage<V>(
  key: BrowserStorageKey<V>,
): [V, (value: V) => void] {
  const value = useSessionStorageValue(key);
  const setter = useSessionStorageSetter(key);

  return [value, setter];
}
