import { useCallback } from "react";

import {
  COLLAPSED_SIDEBAR_WIDTH,
  DEFAULT_SIDEBAR_WIDTH,
  SidebarTab,
} from "../components/sidebar/SidebarOptions";
import { useProjectContext } from "../util/projectContext.js";

import {
  SessionStorageKeys,
  useSessionStorageGetter,
  useSessionStorageSetter,
} from "./useSessionStorage";

export function useOpenSidebar(tab: SidebarTab): () => void;
export function useOpenSidebar(): (tab: SidebarTab) => void;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useOpenSidebar(tab?: SidebarTab) {
  const { hexId } = useProjectContext();
  const setSidebarTab = useSessionStorageSetter(
    SessionStorageKeys.SIDEBAR_VIEW(hexId),
  );
  const setSidebarWidth = useSessionStorageSetter(
    SessionStorageKeys.SIDEBAR_WIDTH(hexId),
  );

  const isDrawerViewCollapsed = useSessionStorageGetter(
    SessionStorageKeys.OUTLINE_COLLAPSED(hexId),
  );
  const setDrawerViewCollapsed = useSessionStorageSetter(
    SessionStorageKeys.OUTLINE_COLLAPSED(hexId),
  );

  return useCallback(
    (tabArg?: SidebarTab) => {
      // because of the overloads one of these must be specified
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see above
      setSidebarTab((tab ?? tabArg)!);
      if (isDrawerViewCollapsed()) {
        setSidebarWidth(DEFAULT_SIDEBAR_WIDTH);
        setDrawerViewCollapsed(false);
      }
    },
    [
      isDrawerViewCollapsed,
      setDrawerViewCollapsed,
      setSidebarTab,
      setSidebarWidth,
      tab,
    ],
  );
}

export const useCloseSidebar = (): (() => void) => {
  const { hexId } = useProjectContext();
  const setSidebarWidth = useSessionStorageSetter(
    SessionStorageKeys.SIDEBAR_WIDTH(hexId),
  );
  const setDrawerViewCollapsed = useSessionStorageSetter(
    SessionStorageKeys.OUTLINE_COLLAPSED(hexId),
  );

  return useCallback(() => {
    setSidebarWidth(COLLAPSED_SIDEBAR_WIDTH);
    setDrawerViewCollapsed(true);
  }, [setSidebarWidth, setDrawerViewCollapsed]);
};
